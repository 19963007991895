<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<p>
									You can watch up to 5 <span class="font-semibold">streams</span> (TV, Phone, Laptop, Computer etc) on
									<span class="font-semibold">one (1) network at a time (simultaneously)</span>
								</p>
								<div class="space-y-2">
									<p>
										Example: John lives in Europe. John has 3 TVs and 2 cell phones in his family home. John can use all of these 5 devices at the same time on
										the same wifi in his home ✅
									</p>
									<p>
										-John can <span class="font-semibold">NOT</span> ✖️use his cellphone to watch a stream at work <span class="font-semibold">while</span> his
										kids are at home watching at the same time on the family TV.
									</p>
									<p>-If John turns off his tv (streams) at his home he can ✅ then use his phone at work or on the go.</p>
								</div>
								<p>
									<span class="font-semibold">In USA, Mexico & Canada only</span>, you can watch up to 5 streams on
									<span class="font-semibold">two (2) different networks at a time.</span>
								</p>
								<p class="font-semibold">Example for USA, Canada and Mexico ONLY.</p>
								<div>
									<p>John lives in USA. John has 2 tv's, 1 laptop, and 2 cellphones within his family.</p>
									<p>
										John can ✅ use all of these 5 devices at the same time at home or have 4 tvs watching streams at home while his wife is at work or on the
										go on her cellphone (2 networks)
									</p>
								</div>
								<div>
									<p>
										Example (4 tvs are streaming on John's home wifi network and John's wife is watching a stream on her cell phone on the mobile data from
										work).
									</p>
									<p>This IS allowed in USA, Canada and Mexico only.</p>
								</div>
								<div class="space-y-2">
									<p class="font-semibold">Q: How do I remove or replace a device from my account?</p>
									<div>
										<p>-We do not limit the amount of installations of the apps on devices.</p>
										<p>-You can install the app on as many devices that you want but you can only watch 5 streams simultaneously.</p>
									</div>
								</div>
								<div class="space-y-2">
									<p class="font-semibold">Q: I have a lost/stolen device how do I stop someone from using my account?</p>
									<div class="space-y-1">
										<p>
											-Simply change your password on our website to log out all of your devices and prevent unwanted usage of your account using the
											following instructions in the link below
										</p>
										<p>
											<router-link :to="{ name: 'RESENDORCHANGEPASSWORD' }" class="text-blue-400"><strong>Resend Or Change Password</strong></router-link>
										</p>
									</div>
								</div>
								<div class="space-y-2">
									<p>Notes:</p>
									<ul class="list-disc">
										<li>
											*You do NOT need to contact us if you want to download the app on to more than 5 devices. You can install the app on all your devices
											but you can only stream from 5 devices at a time.
										</li>
										<li>
											*It is important to make sure that you always log out of the app and uncheck the "save login" checkbox in the settings menu
											<span class="font-semibold">(top left corner icon of the app)</span> if you use your account on a friend or family members device
											temporarily.
										</li>
									</ul>
									<p>*There is <span class="font-semibold">no geographical ip lock.</span></p>
									<p>*we do <span class="font-semibold">not</span> offer discounts for less connections</p>
									<p>
										*If you need more than the 5 devices you will need to purchase additional accounts using a
										<span class="font-semibold">different</span> email address.
									</p>
									<p>*You can install the apps on as many devices as you want, that doesn't count as "watching", only "watching a stream" counts as 1 stream.</p>
									<p>
										*Switching channels too quickly can cause the system to think you are using more devices than allowed and you will receive a message "device
										limit reached". To avoid this simply change channels a little slower and the system will reset itself automatically in a short amount of
										time.
									</p>
									<p>*multi-view apps such as tivimate where multiple channels are watched count as separate streams</p>
									<p>4 channels in multiview = 4 streams etc</p>
									<p>For a list of supported devices and apps visit our FAQ {{ $store.state.brandInfo.faqLink }}</p>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'HowManyDevicesCanBeUsedAtOnce',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
